import React from 'react';
import { useLocation } from 'react-router-dom';
import { BarnesLogo } from '../../assets/icons';
import { Barnes100Logo } from '../../assets/icons';
import { Toggle } from './toggle';
import { ThemeWrapper } from './themeWrapper';

/**
 * Determine if we are at a poll results route.
 * @param {string} pathname - current pathname.
 * @returns {boolean} - if we are at a poll results route, true.
 */
const isPollResultsRoute = (pathname: string) => {
    const routes = pathname
        .split("/")
        .filter(Boolean);

    return (
        routes.length === 2 &&
        routes[1] === 'poll'
    );
};

export const Nav = () => {
    // Get current pathname and determine if we are at a poll results route.
    // We will not render anything if so.
    const { pathname } = useLocation();
    const isPollResults = isPollResultsRoute(pathname);

    // Get year to determine whether to use centennial logo or original logo
    const today = new Date()
    const isCentennial = today.getFullYear() === 2022;

    return (
        isPollResults
        ? <></>
        : (<ThemeWrapper
            className="nav"
            elementType="nav"
            render={({ isDarkTheme, setIsDarkTheme }) => (
                <>
                    {isCentennial ? (
                        <Barnes100Logo className="nav__logo centennial-logo"/>
                    ) : (
                        <BarnesLogo className="nav__logo"/>
                    )}

                    <div className="nav__theme-toggle">
                        ☼
                        <Toggle
                            className="nav__toggle"
                            onClick={() => setIsDarkTheme(!isDarkTheme)}
                            isOn={!isDarkTheme}
                        />
                        ☾
                    </div>
                </>
            )}
        />)
    );
};